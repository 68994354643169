<template>
  <div class="main">
    <div class="header">
      <div class="left">
        <button
          style="background-color: rgb(16, 39, 46)"
          class="left-button"
          @click="ToMap()"
        >
          返回
        </button>

        <div class="area" v-if="type == 'dev'">
          <div>
            <span class="area-title">省市区：</span>
            <el-tooltip
              :content="showContent"
              :disabled="!showContent"
              placement="top"
            >
              <el-cascader
                style="height: 4.8vh"
                ref="cascader"
                :options="pcaTextArr"
                :props="{ checkStrictly: true }"
                v-model="selectedOptions"
                @change="areaChange()"
              >
              </el-cascader>
            </el-tooltip>
          </div>
        </div>
      </div>
      <p class="title">智 慧 平 台 数 据 驾 驶 舱</p>
      <div class="header-right">
        <div class="time">{{ time }}</div>
        <div class="avatar">
          <el-popover
            placement="top"
            width="160"
            v-model="visible"
            trigger="hover"
          >
            <p style="padding: 10px 0px">确定要退出登录吗？</p>
            <div style="text-align: right; margin: 0">
              <el-button size="mini" type="text" @click="cancelLogout()"
                >取消</el-button
              >
              <el-button type="primary" size="mini" @click="logout()"
                >确定</el-button
              >
            </div>
            <!-- <img src="../../assets/home/avatar.png" alt="" slot="reference" /> -->
            <img
              style="width: 2.5rem; border-radius: 50%; border-color: black"
              src="../../assets/home/logo.jpg"
              alt=""
              slot="reference"
            />
          </el-popover>
        </div>
      </div>
    </div>
    <div v-if="selectedCity" class="content">
      <!-- <div class="header-flex"
        style="display: flex;justify-content: space-between;align-items: center;margin-bottom:10px"> -->
      <p class="content-title">{{ cityName }}在线设备</p>
      <!-- <el-button class="device-btn" @click="goDeviceControl()">设备控制</el-button> -->
      <!-- </div> -->

      <div class="content-table">
        <el-table
          :data="onlineDevData"
          style="width: 100%"
          header-row-class-name="tableHeaderColor"
          :cell-style="cellStyle"
          height="60vh"
        >
          <el-table-column
            prop="deviceName"
            label="设备名称"
            align="center"
            width="150"
            show-overflow-tooltip
          >
            <template slot-scope="{ row }">
              <div class="name-cell">{{ row.deviceName }}</div>
            </template>
          </el-table-column>
          <el-table-column
            prop="address"
            label="详细地址"
            width="350"
            align="center"
            show-overflow-tooltip
          >
            <template slot-scope="{ row }">
              {{ row.province + row.city + row.district + row.address }}
            </template>
          </el-table-column>
          <el-table-column
            prop="buildingName"
            label="楼栋"
            width="250"
            show-overflow-tooltip
            align="center"
          ></el-table-column>
          <el-table-column
            prop="floor"
            label="楼层"
             width="90"
            show-overflow-tooltip
            align="center"
          >
          </el-table-column>
          <el-table-column
            prop="buildingNumber"
            label="楼号"
            show-overflow-tooltip
            align="center"
          >
          </el-table-column>

          <el-table-column
            label="更多参数"
            align="center"
            show-overflow-tooltip
          >
            <template slot-scope="scope">
              <a
                class="params-cell"
                type="text"
                size="small"
                style="
                  color: rgb(28, 212, 212);
                  cursor: pointer;
                  text-decoration: underline;
                "
                @click="handleRead(scope.row.id)"
                >查看</a
              >
            </template>
          </el-table-column>
          <el-table-column
            label="图片查看"
            align="center"
            show-overflow-tooltip
          >
            <template slot-scope="scope">
              <a
                class="params-cell"
                type="text"
                size="small"
                style="
                  color: rgb(28, 212, 212);
                  cursor: pointer;
                  text-decoration: underline;
                "
                @click="imageLookClick(scope.row.id)"
                >查看</a
              >
            </template>
          </el-table-column>
          <el-table-column
            label="设备监控"
            align="center"
            show-overflow-tooltip
          >
            <template slot-scope="scope">
              <a
                @click="skipdeviceMonitor(scope.row.id)"
                style="
                  color: rgb(28, 212, 212);
                  cursor: pointer;
                  text-decoration: underline;
                "
                >查看</a
              >
            </template>
          </el-table-column>
          <el-table-column label="3d展示" align="center" show-overflow-tooltip>
            <template slot-scope="scope">
              <a
                @click="skipdevice3D(scope.row.id)"
                style="
                  color: rgb(28, 212, 212);
                  cursor: pointer;
                  text-decoration: underline;
                "
                >查看</a
              >
            </template>
          </el-table-column>
          <el-table-column
            label="模块列表"
            align="center"
            show-overflow-tooltip
          >
            <template slot-scope="scope">
              <a
                @click="modelClick(scope.row.id)"
                style="
                  color: rgb(28, 212, 212);
                  cursor: pointer;
                  text-decoration: underline;
                "
                >查看</a
              >
            </template>
          </el-table-column>
          <el-table-column
            label="设备控制"
            align="center"
            show-overflow-tooltip
          >
            <template slot-scope="scope">
              <a
                @click="skipdeviceControl(scope.row.projectId)"
                style="
                  color: rgb(28, 212, 212);
                  cursor: pointer;
                  text-decoration: underline;
                "
                >跳转</a
              >
            </template>
          </el-table-column>
        </el-table>
        <div class="content-table-footer"></div>
      </div>
      <div class="switchControl" v-show="dialogVisible" @click="closeDialog()">
        <div class="dialogMain" @click="stopClick">
          <p class="dialogTitle">模块开关控制</p>
          <i class="el-icon-close dialogClose" @click="closeDialog()"></i>
          <el-table
            :data="SubModuleList"
            border
            :header-cell-style="{ color: 'rgba(0, 0, 0, 0.85)' }"
            style="width: 100%; margin-top: 20px; color: rgba(0, 0, 0, 0.85)"
          >
            <el-table-column label="序号" width="55" align="center">
              <template slot-scope="scope">
                {{ scope.$index + 1 }}
              </template>
            </el-table-column>
            <el-table-column prop="moduleName" label="模块名称" align="center">
            </el-table-column>
            <el-table-column
              prop="moduleStatus"
              label="开关状态"
              align="center"
            >
              <template slot-scope="scope">
                <el-switch
                  class="demo"
                  v-model="scope.row.moduleStatus"
                  active-color="#13ce66"
                  inactive-color="#909399"
                  active-text="开"
                  inactive-text="关"
                  size="medium"
                  @change="
                    switchChange(
                      scope.row.id,
                      scope.row.moduleStatus,
                      scope.row.deviceId,
                      scope.row.modbusNumber
                    )
                  "
                >
                </el-switch>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { pcaTextArr } from "element-china-area-data";
export default {
  data() {
    return {
      pcaTextArr,
      selectedOptions: ["", ""],
      //  开关控制模块是否显示
      dialogVisible: false,
       // 开关控制模块列表
       SubModuleList: [],
      // 级联选择器显示全
      showContent: "",
      time: "",
      setTime: "",
      // 通过哪种方式进入页面（地图or设备)
      type: "",
      visible: false,
      selectedProvince: "1",
      selectedCity: "11",
      filteredCities: [
        {
          id: "11",
          name: "北京市",
          pid: "1",
        },
      ],
      loading: false,
      onlineDevData: [],
      pagination: {
        page: 1,
        pageSize: 10,
        total: 0,
      },
      totalPage: 1,
      cityName: "全国",
      province: "",
      city: "",
      district: "",
      // 传过来的marker
      marker: {},
    };
  },
  created() {
    // if (this.$route.query.marker) {
    //   localStorage.setItem("marker", this.$route.query.marker);
    // }

    this.marker = JSON.parse(localStorage.getItem("marker"));
    // console.log(this.marker)
    if (this.$route.query.type) {
      localStorage.setItem("type", this.$route.query.type);
    }
    this.type = localStorage.getItem("type");

    let that = this;
    // @ts-ignore
    this.setTime = setInterval(() => {
      that.Date();
    }, 1000);
  },
  mounted() {
    // console.log(this.type)
    if (this.type == "dev") {
      // 级联显示
      // this.handleContent('请选择');
      this.cityName = "全国";
      this.city = "";
      this.district = "";
      this.province = "";
    } else {
      if (this.marker.district) {
        this.cityName = this.marker.district;
        this.district = this.marker.district;
        this.city = "";
      } else if (this.marker.city) {
        this.city = this.marker.city;
        this.cityName = this.marker.city;
      }
    }
    // 初始化表格
    this.initDevTable();
    // 滚动加载
    this.lazyLoading();
  },
  methods: {
     // 关闭dialog框
     closeDialog() {
      this.dialogVisible = false;
    },
     // 跳转至模块列表页面
     modelClick(id) {
      console.log(id);
      localStorage.setItem('modelID',id)
      this.$router.push('/ModelList')
    },
   
    // 阻止父元素事件
    stopClick(event) {
      event.stopPropagation();
    },
    // 设备控制跳转到白象云管理系统
    goDeviceControl() {
      // window.location.href = "https://cloud.zjbaixiang.cn/baixiangyun/#/"
      window.open("https://cloud.zjbaixiang.cn/baixiangyun/#/", "_blank");
    },
    // 获取key
    getRowKeys(row) {
      return row.id;
    },
    // 设备控制跳转
    skipdeviceControl(id) {
      if (id == 4) {
        window.open('https://cloud.zjbaixiang.cn/wuhanguanggu/#/', '_blank')
        // window.open( "http://cloud.zjbaixiang.cn:9898/wuhanguanggu/#/","_blank" );
      } else {
        window.open('https://cloud.zjbaixiang.cn/baixiangyun/#/', '_blank')
        // window.open("http://cloud.zjbaixiang.cn:9898/baixiangyun/#/", "_blank");
      }
    },
    // 跳转设备监控页
    skipdeviceMonitor(id) {
      this.$router.push({ name: "monitor", query: { id: id } });
    },
    // 跳转3d展示页
    skipdevice3D(id) {
      var param = {
        deviceId: id,
      };
      this.$post("/device3dPhoto/list", param)
        .then((res) => {
          // 主要参数
          //   console.log(res);
          if (res.code == 1) {
            console.log(res.data);
            if (res.data.length > 0) {
              this.$router.push({ name: "3DShow", query: { id: id } });
            } else {
              this.$message.warning("暂无3d模型");
            }
          } else {
            this.$message.error(res.msg);
          }
        })
        .catch(() => {
          console.log("异常");
        });
    },
    // 跳转图片查看页
    imageLookClick(id) {
      // console.log(id)
      var param = {
        deviceId: id,
        pageNum: 1,
        pageSize: 20,
      };
      this.$post("/devicePhoto/pageList", param)
        .then((res) => {
          // 主要参数
          //   console.log(res);
          if (res.code == 1) {
            if (res.data.list.length > 0) {
              this.$router.push("/imglook");
              localStorage.setItem("deviceId", id);
            } else {
              this.$message.warning("暂无可查看图片");
            }
          } else {
            this.$message.error(res.msg);
          }
        })
        .catch(() => {
          console.log("异常");
        });
    },
    // 初始化设备表格
    initDevTable() {
      var that = this;
      let arr = [];
      if (localStorage.getItem("projectID") == null) {
        arr = [];
      } else {
        arr = [localStorage.getItem("projectID")];
      }
      let param = {
        // projectList:arr,
        pageNum: that.pagination.page,
        pageSize: that.pagination.pageSize,
        province: that.province,
        city: that.city,
        district: that.district,
        projectList: arr,
      };
      this.$post("/device/pageList", param)
        .then((res) => {
          // console.log(res);
          this.onlineDevData = res.data.list;
          this.totalPage = res.data.pages;
        })
        .catch(() => {
          console.log("异常");
        });
    },
    // 滚动加载表格数据
    lazyLoading() {
      let dom = document.querySelector(".el-table__body-wrapper");
      var that = this;
      let zero = 0;
      dom.addEventListener("scroll", () => {
        const scrollDistance =
          dom.scrollHeight - dom.scrollTop - dom.clientHeight;
        // console.log(scrollDistance);
        // if (scrollDistance <= 0) {  //分辨率问题，如果设置 100% ，滑倒最底部，scrollDistance的值 可能为 0.201 到 -0.201
        if (scrollDistance <= 1) {
          //等于0证明已经到底，可以请求接口
          if (this.pagination.page >= this.totalPage) {
            //判断是否到达底部
            // console.log("我~是有底线的 (～￣▽￣)～");
            if (scrollDistance == 0) {
              zero++;
              // console.log("zero=" + zero);
              if (zero > 1) {
                return;
              } else {
                this.$message.warning("已经到底了~");
              }
              // this.throttle(that.tip(), 5000);
            }
          }
          if (this.pagination.page < this.totalPage) {
            //当前页数小于总页数就请求
            this.pagination.page++; //当前页数自增
            // console.log(
            //   "页面已经到达底部,可以请求接口,请求第 " +
            //   this.pagination.page +
            //   " 页数据"
            // );
            let arr = [];
            if (localStorage.getItem("projectID") == null) {
              arr = [];
            } else {
              arr = [localStorage.getItem("projectID")];
            }
            let param = {
              pageNum: that.pagination.page,
              pageSize: that.pagination.pageSize,
              province: "",
              city: that.city,
              district: that.district,
              projectList: arr,
            };
            this.$post("/device/pageList", param)
              .then((res) => {
                // console.log(res);
                this.onlineDevData = this.onlineDevData.concat(res.data.list);
                this.totalPage = res.data.pages;
              })
              .catch(() => {
                console.log("异常");
              });
          }
        }
      });
    },

    // 选择城市
    areaChange() {
      // console.log(this.selectedOptions);
      let province = this.selectedOptions[0];
      let city = this.selectedOptions[1] || "";
      let district = this.selectedOptions[2] || "";
      this.province = province;
      if (this.selectedOptions.length === 1) {
        this.cityName = province;
        this.city = "";
        district = "";
      } else if (this.selectedOptions.length === 2) {
        this.cityName = `${province} - ${city}`;
        if (
          province === "北京市" ||
          province === "天津市" ||
          province === "上海市" ||
          province === "重庆市"
        ) {
          this.city = province;
        } else {
          this.city = city;
        }
      } else if (this.selectedOptions.length === 3) {
        this.cityName = `${province} - ${city} - ${district}`;
        if (
          province === "北京市" ||
          province === "天津市" ||
          province === "上海市" ||
          province === "重庆市"
        ) {
          this.city = province;
        } else {
          this.city = city;
        }
      }
      this.district = district;
      this.pagination.page = 1;
      this.initDevTable();
      this.handleContent(this.selectedOptions);
    },

    // 级联选择器让其显示全的提示
    handleContent(value) {
      // console.log(value);
      // 获得当前组件
      const cascader = this.$refs.cascader;
      // 根据变化后的值获得节点信息
      // 如果项目中绑定的value值经过处理不是数组而是选中的叶子节点的value，则可以直接获取节点
      // const node = cascader.panel.getNodeByValue(value)
      // 否则需要先获得叶子节点值再获得节点。由于cascader默认保存的value数据是按从父到子的顺序保存的，因此数组中的最后一个值就是叶子节点值
      const leaf = value[value.length - 1];
      const node = cascader.panel.getNodeByValue(leaf);
      // 获得全路径内容
      this.showContent = node.getText(
        cascader.showAllLevels,
        cascader.separator
      );
    },

    // 返回按钮
    ToMap() {
      this.$router.push("/map");
    },
    // 获取时间戳
    Date() {
      const nowDate = new Date();
      const date = {
        year: nowDate.getFullYear(),
        month: nowDate.getMonth() + 1,
        date: nowDate.getDate(),
        hours: nowDate.getHours(),
        minutes: nowDate.getMinutes(),
        seconds: nowDate.getSeconds(),
      };
      const newmonth = date.month >= 10 ? date.month : "0" + date.month;
      const newday = date.date >= 10 ? date.date : "0" + date.date;
      const newHours = date.hours >= 10 ? date.hours : "0" + date.hours;
      const newminutes = date.minutes >= 10 ? date.minutes : "0" + date.minutes;
      const newseconds = date.seconds >= 10 ? date.seconds : "0" + date.seconds;

      this.time =
        date.year +
        "年" +
        newmonth +
        "月" +
        newday +
        "日" +
        newHours +
        ":" +
        newminutes +
        ":" +
        newseconds;
    },
    // 退出登录
    logout() {
      this.$get("/user/logout")
        .then((res) => {
          if (res.code == 1) {
            this.$router.push("/Login");
            this.$message({
              type: "success",
              message: "登出成功!",
            });
          } else {
            this.$message.error(res.msg);
          }
        })
        .catch(() => {
          console.log("异常");
        });
    },
    cancelLogout() {
      this.visible = false;
      this.$message({
        type: "info",
        message: "已取消登出",
      });
    },
    // 查看参数
    handleRead(row) {
      console.log(row);
      this.$router.push({ name: "detail", params: { id: row } });
      localStorage.setItem("deviceId", row);
    },
    // 表格单元样式
    cellStyle() {
      return "background: rgb(16, 39, 46) !important";
    },
    // 获取在线设备名
    getCityName(e) {
      // console.log(e);
      var selectIndex = e.target.selectedIndex - 1;
      if (selectIndex > -1) {
        this.cityName = this.filteredCities[selectIndex].name;
      }
    },
  },
  beforeDestroy() {
    // 清除定时器
    if (this.setTime) {
      clearInterval(this.setTime);
    }
  },
};
</script>

<style lang="scss" scoped>
// 表头
::v-deep .tableHeaderColor th {
  background: rgb(16, 39, 46) !important;
  // opacity: 0.2;
  color: rgb(211, 230, 248);
  font-family: PingFang SC;
  font-size: 0.83vw;
  line-height: 21px;
  letter-spacing: 0px;
  padding: auto 30px;
}

::v-deep .el-input__inner {
  box-shadow: inset 0px 1px 17px rgb(11, 166, 167);
  background: #000;
  color: #fff;
  border: 1px solid rgb(11, 166, 167);
  height: 4vh;
  width: 12vw;
}

//滚动条的宽度
::v-deep ::-webkit-scrollbar {
  width: 10px;
  height: 10px;
  background-color: #e4e4e4;
  border-radius: 3px;
}

//滚动条的滑块
::v-deep ::-webkit-scrollbar-thumb {
  background-color: #033d3d !important;
  border-radius: 3px;
}

.main {
  background: url("../../assets/drive/big-screen-backimage.svg");
  margin: 0px; //外边距0
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -10;
  zoom: 1;
  background-color: #07080e;
  background-repeat: no-repeat;
  background-size: cover;
  -webkit-background-size: cover;
  -o-background-size: cover;
  background-position: center 0;
  color: #fff;

  .header {
    display: flex;

    .left {
      position: absolute;
      top: 3.5vh;
      left: 1.563vw;
      display: flex;
      .left-button {
        display: inline-block;
        height: 4vh;
        line-height: 4vh;
        border: 1px solid rgb(11, 166, 167);
        box-shadow: inset 0px 1px 17px rgb(11, 166, 167);
        // background: #000;
        color: rgb(195, 226, 249);
        font-size: 1.04vw;
        border-radius: 7px;
        padding: 0 0.52vw;
        white-space: nowrap;
        box-sizing: border-box;
      }

      .area {
        // width: 28vw;
        display: flex;
        margin-left: 10px;
        align-items: center;
        .area-title {
          color: rgb(195, 226, 249);
          font-family: PingFang SC;
          font-size: 1.14vw;
          letter-spacing: 0px;
          text-align: center;
          margin: auto 5px;
        }

        .area-select {
          height: 4vh;
          box-sizing: border-box;

          select {
            outline: none;
            appearance: none;
            /* 去掉默认图标 */
            -moz-appearance: none;
            /* Firefox */
            -webkit-appearance: none;
            background: url("../../assets/drive/drop-box.png") no-repeat scroll 95%
              50% transparent;
            background-size: 0.781vw;
            width: 5.5vw;
            height: 4vh;
            box-sizing: border-box;
            padding: 0 10px;
            font-size: 1.04vw;
            border: 1px solid rgb(11, 166, 167);
            box-shadow: inset 0px 1px 17px rgb(11, 166, 167);
            color: rgb(195, 226, 249);
            font-family: PingFang SC;
            border-radius: 7px;
          }
        }
      }
    }

    .title {
      width: 100%;
      text-align: center;
      // margin-left: -0.5vw;
      background: linear-gradient(
        180deg,
        rgba(238, 238, 238, 1),
        rgba(113, 244, 236, 1)
      );
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
      // text-fill-color: transparent;
      font-family: PingFang SC;
      font-size: 2.4vw;
      cursor: pointer;
    }

    .header-right {
      position: absolute;
      right: 1.563vw;
      top: 3.5vh;
      width: 25%;
      display: flex;
      align-items: center;

      .time {
        // margin-left: 20px;
        width: 80vw;
        text-align: right;
        color: rgb(195, 226, 249);
        font-family: PingFang;
        font-size: 1.04vw;
        line-height: 26px;
        letter-spacing: 0px;
        cursor: pointer;
      }

      .avatar {
        margin: 0 0 0 15px;
        width: 10vw;
      }
    }
  }

  // 表格
  .content {
    margin: 6vh 1.563vw;
    font-size: 1.302vw;

    .content-title {
      margin-bottom: 10px;
      cursor: pointer;
    }

    // 设备控制按钮
    .device-btn {
      display: inline-block;
      height: 4vh;
      line-height: 4vh;
      border: 1px solid rgb(11, 166, 167);
      box-shadow: inset 0px 1px 17px rgb(11, 166, 167);
      background: #000;
      color: rgb(195, 226, 249);
      font-size: 1.04vw;
      border-radius: 7px;
      padding: 0 0.52vw;
      white-space: nowrap;
      box-sizing: border-box;
      // margin-bottom: 20px;
    }

    .content-table {
      position: relative;
      border: 2px solid rgba(98, 255, 246, 0.1);
      padding: 10px 10px 50px;
      height: 64vh;
      // overflow-y: auto;
      // overflow-x: hidden;
      // box-shadow: 0px -1px 1px rgba(98, 255, 246, 0.1); /*阴影出现在元素上方*/
      // box-shadow: 0px 1px 1px rgba(98, 255, 246, 0.1); /*阴影出现在元素下方*/
      background: linear-gradient(
        180deg,
        rgba(19, 32, 36, 1),
        rgba(98, 255, 246, 0.1)
      );

      &::before {
        display: block;
        content: "";
        height: 0.75rem;
        position: absolute;
        left: -2px;
        top: -1px;
        border-left: 2px solid rgb(35, 238, 231);
        border-top: 2px solid rgb(35, 238, 231);
      }

      &::after {
        display: block;
        content: "";
        height: 0.75rem;
        position: absolute;
        right: -2px;
        top: -1px;
        border-right: 2px solid rgb(35, 238, 231);
        border-bottom: 2px solid rgb(35, 238, 231);
      }

      .content-table-footer {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;

        &::before {
          display: block;
          content: "";
          height: 0.75rem;
          position: absolute;
          left: -2px;
          bottom: -1px;
          border-left: 2px solid rgb(35, 238, 231);
          border-bottom: 2px solid rgb(35, 238, 231);
        }

        &::after {
          display: block;
          content: "";
          height: 0.75rem;
          position: absolute;
          right: -2px;
          bottom: -1px;
          border-right: 2px solid rgb(35, 238, 231);
          border-bottom: 2px solid rgb(35, 238, 231);
        }
      }

      /*最外层透明*/
      ::v-deep .el-table,
      ::v-deep .el-table__expanded-cell {
        background-color: transparent !important;
      }

      /* 表格内背景颜色 */
      ::v-deep .el-table th,
      ::v-deep .el-table tr,
      ::v-deep .el-table td {
        // background-color: transparent !important;
        border: 0; //去除表格
        height: 7vh !important;
      }

      // 表格内字体
      ::v-deep .el-table {
        color: rgb(211, 230, 248);
        font-family: PingFang SC;
        font-size: 0.83vw;
        line-height: 7vh;
        letter-spacing: 0px;
        text-align: center;

        &::before {
          display: none;
        }

        // 表格行距
        .el-table__body {
          // -webkit-border-horizontal-spacing: 13px;  // 水平间距
          -webkit-border-vertical-spacing: 10px; // 垂直间距 设置的是行间距
          // border-collapse: separate;
          // border-spacing: 5px;
        }

        thead th,
        tbody .el-table__row td {
          padding: 10px 0;
        }
      }

      // 表格底部边框
      .el-table::before {
        background-color: transparent !important;
      }
    }
  }
}
</style>
<style>
.el-cascader-panel {
  height: 200px !important;
}

.el-cascader-menu__wrap.el-scrollbar__wrap {
  overflow-x: hidden;
}
</style>
